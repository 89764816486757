/* eslint-disable @typescript-eslint/no-empty-object-type */
import { ReactNode, forwardRef } from 'react';

export type NoopType = () => void;

type Input = {
  focus?: any;
  inner?: any;
  current?: any;
  value?: any;
};

export type InputRef = React.RefObject<HTMLElement> & Input;
export type FileInput = HTMLElement & Input;

export type TGenericObject<TKey extends PropertyKey = string, TField = any> = Record<TKey, TField>;

export type PaginationComplexClassName = {
  base?: string;
  selected?: string;
};

export type LocationData = {
  city: string;
  state: string;
  latitude?: number;
  longitude?: number;
  geoHash?: string;
};

export type TResume = {
  id: bigint;
  filename: string;
  createdAt: Date;
  s3Path: string;
};

export type TProfileFields = {
  locations: string[];
  keywords: string[];
  jobTitles: string[];
  includeRemote: boolean;
  createdAt: Date;
  manuallyUpdatedAt?: Date | null;
  resume: TResume | null;
  nameData: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  dailyUpdateCount?: number;
};

export type TProfileFieldsCN = {
  settingsArray1: string[];
  settingsArray2: string[];
  settingsArray3: string[];
  locations: string[];
  createdAt: Date;
  manuallyUpdatedAt?: Date | null;
  resume: TResume | null;
  nameData: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
  dailyUpdateCount?: number;
};

export function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  return forwardRef(render as any) as any;
}

export type TJSJobAPIType = {
  id: string;
  title: string;
  normalizedTitle: string;
  company?: string | { name: string };
  location: { city?: string; state?: string };
  description: string;
  status?: string;
  cpc: number;
  date?: Date;
  keywords: { value: string }[];
  allBullets: string[];
};

export type TJSJobType = {
  id: string;
  title: string;
  company: string;
  jobPageUrl: string;
  location: LocationData;
  description?: string;
  jobUrls?: Array<{ source: string; cpc?: string }>;
  cpc?: number;
  date?: Date;
};

export type TFetchTypes = 'fetchSimilar' | 'readyForFetch' | 'fetchCategories' | 'canvasnursing';

export type TBreadcrumbs = Array<{ text: string; link?: string }>;

export type TDropdownOptionElement = {
  type: string;
  title: string;
  titleBlock?: ReactNode;
  href?: string;
  onClick?: any;
  isActive?: boolean;
  icon?: ReactNode;
  topChild?: ReactNode;
  withDivider?: boolean;
  [key: string]: any;
};

export type TEmailFrequency = 'daily' | 'otherDay' | 'weekly';
